import {
  CHAIN_NAME,
  expandChainShorthand,
  extractContractAddress,
  extractTokenId,
} from '../../components/report_detail/counterfeit_detail'
import {
  OPENSEA_LOGO_URL,
  RARIBLE_LOGO_URL,
  MAGICEDEN_LOGO_URL,
  X2Y2_LOGO_URL,
  LOOKSRARE_LOGO_URL,
  JUNGLE_LOGO_URL,
} from '@/utils/image'
import {
  extractChainFromNftOrCollection,
  extractCollectionSlugFromNftOrCollection,
} from './match_utils'

const OPENSEA_STOREFRONT_CONTRACT_ADDRESS = '0x495f947276749ce646f68ac8c248420045cb7b5e'
const POLYGON_STOREFRONT_CONTRACT_ADDRESS = '0x2953399124f0cbb46d2cbacd8a89cf0599974963'

export function getMarketplacesForCollection(collection): Array<Marketplace> {
  const marketplaces = collection.marketplace_collections?.map(
    (mc) => mc.marketplace.name,
  )
  return !marketplaces || marketplaces.length === 0
    ? [Marketplace.Opensea]
    : marketplaces
}

export function extractMarketplaceData(collection, marketplaceName) {
  return collection.marketplace_collections?.find((mc) => {
    return mc.marketplace.name == marketplaceName
  })
}

export function isNftDelistedOnMarketplace(nft, marketplaceName): boolean {
  if (!nft) {
    return false
  }
  return nft.nft_marketplace_delists?.find((mc) => {
    return mc.marketplace.name == marketplaceName
  })?.delist_detected_at
}

export function isNftInSharedStorefrontCollection(
  selectedReport,
  nft,
  marketplaceName,
) {
  const contractAddress =
    nft?.contract_address || selectedReport?.flagged_collection?.contract_address
  if (marketplaceName == Marketplace.Opensea || marketplaceName == Marketplace.Jungle) {
    return false
  }

  return (
    contractAddress == OPENSEA_STOREFRONT_CONTRACT_ADDRESS ||
    contractAddress == POLYGON_STOREFRONT_CONTRACT_ADDRESS
  )
}

export enum Marketplace {
  Opensea = 'Opensea',
  Rarible = 'Rarible',
  Magiceden = 'Magiceden',
  X2Y2 = 'X2Y2',
  Looksrare = 'Looksrare',
  Jungle = 'Jungle',
}

export function getMarketplaceLogoUrl(marketplace: Marketplace) {
  switch (marketplace) {
    case Marketplace.Opensea:
      return OPENSEA_LOGO_URL
    case Marketplace.Rarible:
      return RARIBLE_LOGO_URL
    case Marketplace.Magiceden:
      return MAGICEDEN_LOGO_URL
    case Marketplace.X2Y2:
      return X2Y2_LOGO_URL
    case Marketplace.Looksrare:
      return LOOKSRARE_LOGO_URL
    case Marketplace.Jungle:
      return JUNGLE_LOGO_URL
    default:
      return null
  }
}

export function getMarketplaceLinkForNft(
  marketplace: Marketplace,
  selectedReport,
  selectedMatch,
  isFlagged,
): string {
  let tokenId
  let contractAddress
  let chain
  if (isFlagged) {
    tokenId = extractTokenId(selectedMatch?.flagged_nft)
    contractAddress = extractContractAddress(selectedMatch?.flagged_nft)
    chain = extractChainFromNftOrCollection(selectedReport.flagged_collection)
  } else {
    if (!selectedMatch?.asset?.nft) {
      return undefined
    }
    contractAddress = extractContractAddress(selectedMatch?.asset?.nft)
    tokenId = extractTokenId(selectedMatch?.asset?.nft)
    chain = extractChainFromNftOrCollection(selectedReport.original_collection)
  }

  switch (marketplace) {
    case Marketplace.Opensea:
      return (
        'https://opensea.io/assets/' +
        expandChainShorthand(chain) +
        '/' +
        contractAddress +
        '/' +
        tokenId
      )
    case Marketplace.Rarible:
      return chain == 'eth'
        ? 'https://rarible.com/token/' + contractAddress + ':' + tokenId
        : 'https://rarible.com/token/' +
            CHAIN_NAME[chain] +
            '/' +
            contractAddress +
            ':' +
            tokenId
    case Marketplace.X2Y2:
      return 'https://x2y2.io/eth/' + contractAddress + '/' + tokenId
    case Marketplace.Looksrare:
      return 'https://looksrare.org/collections/' + contractAddress + '/' + tokenId
    case Marketplace.Jungle:
      return (
        'https://jungle.co/assets/' +
        selectedMatch?.flagged_nft.nft_marketplace_delists[0]?.marketplace_nft_id
      )
  }
  return undefined
}

export function getMarketplaceLinkForCollection(
  marketplace: Marketplace,
  selectedReport,
  selectedMatch,
  isFlagged,
): string {
  let slug
  let contractAddress
  let chain
  if (isFlagged) {
    slug = extractCollectionSlugFromNftOrCollection(selectedReport.flagged_collection)
    contractAddress =
      extractContractAddress(selectedMatch?.flagged_nft) ||
      extractContractAddress(selectedReport.flagged_collection)
    chain = extractChainFromNftOrCollection(selectedReport.flagged_collection)
  } else {
    slug = extractCollectionSlugFromNftOrCollection(selectedReport.original_collection)
    contractAddress = extractContractAddress(selectedReport.original_collection)
    chain = extractChainFromNftOrCollection(selectedReport.original_collection)
  }

  switch (marketplace) {
    case Marketplace.Opensea:
      return 'https://opensea.io/collection/' + slug
    case Marketplace.Rarible:
      return extractChainFromNftOrCollection(selectedReport.flagged_collection) == 'eth'
        ? 'https://rarible.com/collection/' + contractAddress
        : 'https://rarible.com/collection/' + CHAIN_NAME[chain] + '/' + contractAddress
    case Marketplace.X2Y2:
      return 'https://x2y2.io/collection/' + contractAddress
    case Marketplace.Looksrare:
      return 'https://looksrare.org/collections/' + contractAddress
    case Marketplace.Jungle:
      const jungleMarketplace = // eslint-disable-line no-case-declarations
        selectedReport.flagged_collection.marketplace_collections.find(
          (collection) => collection.marketplace.name === Marketplace.Jungle,
        )
      return 'https://jungle.co/collection/' + jungleMarketplace?.collection_slug
  }
  return undefined
}
